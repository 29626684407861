@media all and (max-width: 380px)

	.footer__menu
		width 100%
	.footer__copyright
		flex-direction: column
		align-items: flex-start
	.footer__copyright--logo
		margin-top 24px
	.select--tabs
		.selectric-items
			li
				font-size 10px
	.profile-info
		&__buttons
			margin-bottom 16px
			padding-bottom 16px
		&__extra
			.row
				&:not(:last-child)
					margin-bottom 16px
				&--balance
					flex-direction column
					border-bottom 1px solid var(--border-color)
				.currency
					&--bonus
						border 0
						padding 0 0 16px
						height 56px
						max-width 100%
					&--real
						margin-bottom 16px
						max-width 100%
