@media all and (max-width: 640px)

	// ---- Main ------

	.container
		padding 0 16px

	.form__section--2
		grid-template-columns: repeat(1,1fr)
	.form--order
		grid-template-columns: repeat(1,1fr)
		& .form__input--textarea
			grid-column auto
