/*
	Всплывающая подсказка при наведении на иконки
*/
.tooltip
	visibility: hidden;
	max-width 160px
	width: max-content
	background: var(--background-body)
	font-size 14px
	border-radius: 4px
	text-align: center
	padding: 4px 12px
	position: absolute
	z-index: 2
	transform translateY(-50%)
	top: 50%
	left: 100%
	opacity: 0
	transition: opacity 0.3s
	box-shadow var(--box-shadow-simle)
