.welcome-block
    color #FFFFFF
    font-size: 32px
    line-height: 1
    padding-top 42px
    text-align right
    & h5
        line-height 1.2
        text-transform: uppercase
        text-shadow: black 3px 2px 0
        & span
            color #d35143