.form__input
	/* ------ label stuff ----------------------------------------------------- */
	&-label:empty
		display: none

	&-label
		display: block
		width: 100%
		line-height: 14px
		text-align: left
		color: #b0a39b
		font-weight: 600

	/* ------ disabled inputs after label ------------------------------------- */
	&-label + input[type='text']:disabled,
	&-label + input[type='password']:disabled,
	&-label + input[type='email']:disabled,
	&-label + input[type='tel']:disabled,
	&-label + textarea:disabled
		color: #3D4047
		border-color: #3D4047
		cursor: default

	/* ----- error ------------------------------------------------------------ */
	&.error
		&-label + input[type='text'],
		&-label + input[type='password'],
		&-label + input[type='email'],
		&-label + input[type='tel'],
		&-label + textarea
			border-color: $color-error

	/* ----- success ---------------------------------------------------------- */
	&.success
		&-label + input[type='text'],
		&-label + input[type='password'],
		&-label + input[type='email'],
		&-label + input[type='tel'],
		&-label + textarea
			border-color: $color-success
