// -----------------------------------------------------------------------------
// Component - Form ------------------------------------------------------------
// -----------------------------------------------------------------------------
.form
	display: flex
	flex-wrap: wrap
	justify-content: space-around
	&--order
		display: grid
		grid-template-columns: repeat(2,1fr)
		grid-gap: 0 32px
		margin-bottom 50px
		& .form__input--textarea
			grid-column: 1 / 3


	&__title
		text-align center
		margin-bottom 15px
	/* ----- form in modals --------------------------------------------------- */
	&--modal
		display flex
		align-items center
		margin 0 auto
	/* ----- form in notification: confirm ------------------------------------ */
	&--confirm
		display: flex
		justify-content: center
		width: 100%
		max-width: 440px
		& .form__section--submit
			margin-top: 0
	/* ----- Секция формы (fieldset) ------------------------------------------ */
	&__section
		width 100%
		&--2
			display grid
			grid-template-columns: repeat(2,1fr)
			grid-gap: 0 32px
		&--submit
			text-align center
		&--wrapper
			display flex
			flex-wrap wrap
			justify-content space-between
		/* wrapper for submit button */
		&--submit
			& .button + .button
				margin-top: 20px
	/* ----- Сообщение / Ошибка у элемента формы ------------------------------ */
	&__error-message
		display none
		width 100%
		font-size: 10px
		line-height: 12px
		color: $color-error
		font-weight: 400
		text-align: left
		padding: 6px 16px
		letter-spacing: -0.5px

	/* ----- Сообщение / Успех у элемента формы ------------------------------- */
	&__success-message
		display none
		width 100%
		font-size: 12px
		line-height: 14px
		color: $color-success
		font-weight: 400
		text-align: left
		padding: 5px 0 0
	&__input
		/*
		*  Вывод уведомления об ошибке или успешном действии под элементом формы
		*/
		&.error,
		&.success
			& ^[-2]
				/ &__notification
					display block
					padding 6px 4px
					font-size 10px
					text-align left
		&.disabled
			& input
				&:disabled
					color $text-color-gray
					border-color #2C2A37
					cursor default
					opacity .75
