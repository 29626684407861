.contact-block
    text-align center
    font-weight 600
    text-transform uppercase
    margin-top 12px
    font-family $font-title
    & h3
        color #c9c9c9
        font-size 20px
        margin-bottom 0
    &__text
        display flex
        flex-direction column
        color #FFFFFF
        font-size 18px
        &:not(:last-child)
            margin-bottom 12px
        & p
            margin-bottom 12px
        & span
            color: #d35143
        & .button span
            color #FFFFFF
        & a
            &:hover
                & span
                    color: #e9af45
.alert
    margin-bottom: 16px
    font-size: 14px
    padding: 16px
    &-success
        background-color #b1edaa
    &-danger
        background-color #ffdede

.invalid-feedback
    color: #d35143
    font-size: 12px
    position: absolute
    top: 100%
    left: 0
