.mobile
	&-menu
		&__toggle
			display none
			justify-content center
			align-items center
			height 64px
			width: 64px
			padding 20px
			&.active
				background-color: #050505
			span:after, span:before
				content: ""
				position: absolute
				left: 0
				top: -9px
			span:after
				top: 9px
			span
				position: relative
				display: block
			span, span:after, span:before
				width: 100%
				height: 2px
				background-color: #fff
				transition: all 0.3s
				backface-visibility: hidden
				border-radius: 2px
			&.active span
				background-color: transparent

			&.active span:before
				transform: rotate(45deg) translate(6px,7px)
				width 28px
				background-color: #d35143

			&.active span:after
				transform: rotate(-45deg) translate(5px,-7px)
				width 28px
				background-color: #d35143


