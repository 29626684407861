/***
*
* iconfont.styl генерируется динамечески со списком классов для иконочного шрифта
* _iconfont-template.styl - шаблон для генерации файла iconfont.styl
*
***/

/* Генерация рандомного числа, для обнуления кеша при деплое на песок и прод */
$rand = math(math(0, 'random') * 9999999 + 1, 'floor')

@font-face
	font-family: "iconfont";
	src: url('../fonts/iconfont/iconfont.eot?v='$rand);
	src: url('../fonts/iconfont/iconfont.eot?v='$rand'#iefix') format('eot'),
		url('../fonts/iconfont/iconfont.woff2?v='$rand) format('woff2'),
		url('../fonts/iconfont/iconfont.woff?v='$rand) format('woff'),
		url('../fonts/iconfont/iconfont.ttf?v='$rand) format('truetype'),
		url('../fonts/iconfont/iconfont.svg?v='$rand'#iconfont') format('svg');


[class^="icon-"], [class*=" icon-"]
	display flex
	justify-content center
	align-items center
	&::before
		font-family "iconfont"
		speak none
		-webkit-font-smoothing antialiased
		-moz-osx-font-smoothing grayscale
		font-style normal
		font-variant normal
		font-weight normal
		text-decoration none
		text-transform none


.icon-close::before
	content "\E001"

.icon-menu::before
	content "\E002"

.icon-slide-next::before
	content "\E003"

.icon-slide-prev::before
	content "\E004"

.icon-facebook::before
	content "\E005"

.icon-google::before
	content "\E006"

.icon-instagram::before
	content "\E007"

.icon-telegram::before
	content "\E008"

.icon-twitter::before
	content "\E009"

.icon-vk::before
	content "\E00A"
