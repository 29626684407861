// -----------------------------------------------
// Component - Socials
// -----------------------------------------------
.socials
	display flex
	align-items center
	justify-content center
	&__item
		&:not(:last-child)
			margin-right 12px
	&__link
		&.inactive
			i
				color #8C899A
				border-color #8C899A
		&.linked
			i
				background: linear-gradient(273.8deg, #D96331 15.88%, #E99018 98.99%)
				color: #F6F7FC
				border: 2px solid #F6F7FC
		&:hover
			i
				background-color var(--button-bg-hover)
		&:active
			i
				box-shadow: var(--box-shadow-inset)
		i
			width 36px
			height 36px
			font-size 18px
			color #707585
			border-radius 100%
			background: var(--button-bg)
			box-shadow: var(--box-shadow)
			transition all .4s ease-out



.form__section--links.socials
	& .socials__list
		justify-content center
