// -----------------------------------------------
// Component - Button
// -----------------------------------------------
.button
	display inline-flex
	align-items center
	justify-content center
	height 40px
	font-size 14px
	box-sizing: border-box
	text-align: center
	user-select: none
	padding: 0 20px
	white-space: nowrap
	overflow: hidden
	font-weight 600
	text-decoration none
	transition: all 0.5s
	cursor pointer
	border-radius 6px
	font-family $font-title
	&.mobile
		display none
	& i
		font-size 20px
		margin-right 10px
		text-shadow: none
	&:hover
		transition: all 0.5s
	&--s1
		width 100%
		max-width 300px
	&--s2
		max-width: 300px
	&--s3
		max-width: 200px
	&--t1
		color: #FFFFFF
		text-transform uppercase
		background-color #d35143
	&--t2
		color: #FFFFFF
		text-transform uppercase
		background-color #e9af45
		&:hover
			background #d35143
	&:disabled
		background: #B3B6BC
		border: 2px solid #EEF0F6
		cursor default
		color #EEF0F6
		&.button--t1,
		&.button--t2,
		&.button--t3
			&:hover
				background #B3B6BC