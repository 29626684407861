.special-radio
	// ----- list stuff ----------------------------------------------------------
	&__list
		padding-bottom: 10px
		width 100%
		&-heading
			text-align: center
			font-weight: 400
			margin-bottom: 10px

	// ----- item stuff ----------------------------------------------------------

	border: 2px solid transparent
	margin-bottom: 16px
	background: var(--background-body)
	box-shadow: var(--box-shadow)
	border-radius: 8px

	&__active
		border-color #E98F1A

		.special-radio__label
			min-height: 52px
			padding: 16px 52px

	&__hidden-input
		display: none
		visibility: hidden

	&__label
		display: flex
		align-items: center
		min-height: 52px
		font-weight: 600
		font-size: 16px
		line-height: 20px
		color: var(--title-font-color)
		text-align: left
		padding: 0 52px
		transition: $t-color
		position: relative
		cursor: pointer

		& .label__bg
			z-index 1
			//&:before
			//	content ''
			//	position absolute
			//	display block
			//	left 0
			//	right 0
			//	height calc(100% - 2px)
			//	top 50%
			//	transform translateY(-50%)
			//	z-index -1
			//	background-image linear-gradient(273.8deg, #D96331 15.88%, #E99018 98.99%);
			//	background-position 100% 0
			//	background-size 200% 100%
			//	transition all .25s ease-in
		&:before,
		&:after
			content: ""
			position: absolute
		&:before
			width: 20px
			height: 20px
			border 3px solid var(--element-border-color)
			border-radius 50%
			top: 50%
			left: 16px
			box-shadow: var(--box-shadow-inset)
			transform: translateY(-50%)
			z-index 2
		&:after
			width: 8px
			height: 8px
			left: 22px
			top: 50%
			border-radius 50%
			background linear-gradient(273.8deg, #D96331 15.88%, #E99018 98.99%);
			transform: translateY(-50%)
			transition: $t-opacity
			opacity: 0
			z-index 2

	//&__hidden-input:checked + &__label
	//	color: #ffffff
	//	& .label__bg
	//		&:before
	//			background-position 0 0

	//&__label:hover
	//	color: $primary-yellow

	&__hidden-input:checked + &__label:after
		opacity: 1

	&__content
		display: none
		text-align: left
		padding: 0 52px 16px 52px

	&__hidden-input:checked ~ &__content
		display: block

	&__row
		//display: flex
		font-size: 12px
		line-height: 18px
		color: #B3B6BC
		margin-bottom: 5px

		&--small
			font-size: 12px
			line-height: 14px
			padding-top: 5px
			padding-bottom: 5px

		& .bold-text
			color var(--title-font-color)
			margin-left: 5px

	&__link
		color: $color-accent
		font-weight: 500
		cursor: pointer

		&:hover
			text-decoration: underline
