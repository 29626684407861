.features
    &__list
        display: grid
        grid-template-columns: repeat(4,1fr)
        grid-gap: 32px
        width: 100%
    &__item
        background-color #FFFFFF
        padding: 15px 10px
        text-align center
        border-radius: 8px
        & h3
            font-size 20px
            text-transform uppercase
        & p
            margin-bottom 0