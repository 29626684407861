@media all and (min-width: 2200px)
	body
		& > *
			zoom 120%

	.search__wrapper .search-bar
		width 100%


@media all and (min-width: 2800px)
	body
		& > *
			zoom 150%

@media all and (min-width: 3840px)
	body
		& > *
			zoom 200%

