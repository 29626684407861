.mobile
	&-bar
		display none
		position fixed
		left 0
		right 0
		bottom 0
		z-index 12
		background: var(--background-body)
		box-shadow: var(--box-shadow)
		&__layout
			position relative
			display:flex
			height 52px
			width 100%
			@supports (display: grid)
				display grid
				grid-template-columns 1fr 60px 1fr
				align-items: center
			& > div
				display flex
				align-items center
				justify-content space-around
		&__menu
			&.opened
				color #D96331
		& i
			font-size 24px
		&--left, &--right
			& a
				display flex
				align-items center
				font-size 12px
				color: #B3B6BC
				& i
					margin-right 8px
		&--center
			justify-content center
			& a
				position relative
				margin-top -10px
				display flex
				justify-content center
				align-items center
				width 62px
				height 62px
				border-radius 32px
				background: var(--background-body)
			& i
				width 40px
				height 40px
				border-radius 20px
				background: var(--background-body)
				box-shadow: var(--box-shadow)



