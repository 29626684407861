// -----------------------------------------------
// Fix
// -----------------------------------------------
html, body
	overflow-x: hidden

html
	-ms-text-size-adjust: 100%
	-webkit-text-size-adjust: 100%
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	-webkit-tap-highlight-color: transparent

body
	margin: 0
	min-height: 100vh
	text-decoration-skip: none

iframe
	border: 0

main
	display: block

ul, ol
	margin-top: 0
	margin-bottom: 0
	padding-left: 0

li
	display: block

dl
	margin-top: 0
	margin-bottom: 0

dd
	margin-left: 0

h1, h2, h3, h4, h5, h6
	margin-top: 0
	margin-bottom: 0
	font-size: inherit

blockquote
	margin: 0
	padding: 0

p
	margin-top: 0
	margin-bottom: 0

sup
	position: relative
	top: -0.5em
	vertical-align: baseline
	font-size: 75%
	line-height: 0

strong
	font-weight: bold

figure
	margin: 0

fieldset
	margin: 0
	padding: 0
	border: none

img
	max-width: 100%
	height: auto
	border: 0
	vertical-align: middle

a
	color: inherit
	text-decoration: none

button
	overflow: visible
	margin: 0
	padding: 0
	border: 0
	background: none
	text-align: inherit
	text-transform: inherit
	letter-spacing: inherit
	font: inherit
	cursor: pointer
	-webkit-font-smoothing: inherit

button, [type='text'], [type='password'], [type='submit'], [type='search'], [type='email'], [type='tel'], textarea
	outline: none
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none

::-moz-focus-inner
	padding: 0
	border: 0

table
	border-spacing: 0
	border-collapse: collapse

th
	vertical-align: middle

td
	vertical-align: middle

*, *::before, *::after
	box-sizing: border-box
	outline: 0

input
	&::-ms-clear, &::-ms-reveal
		display: none

	&[type='radio'], &[type='checkbox']
		margin: 0

	&[type='search']::-webkit-search-decoration,
	&[type='search']::-webkit-search-cancel-button,
	&[type='search']::-webkit-search-results-button,
	&[type='search']::-webkit-search-results-decoration
		display: none

#titlediv
	display none
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	background-color: #707585
	border: 0;
	border-bottom: 0 solid #3d4047;
	-webkit-text-fill-color: #707585;
	-webkit-box-shadow: var(--box-shadow-inset)
	transition: background-color 5000s ease-in-out 0s;
}
input
	&:-internal-autofill-selected,
	&:-internal-autofill-previewed
		color var(--title-font-color) !important
		background-color var(--background-body) !important
		//-webkit-animation-delay: 1s
		//-webkit-animation-name: autofill
		//-webkit-animation-fill-mode: both
		-webkit-box-shadow: var(--box-shadow-inset),  0 0 0px 1000px var(--background-body) inset !important
		-webkit-text-fill-color: var(--title-font-color) !important





//@keyframes onAutoFillStart
//	from
//		padding-left: 5px
//	to
//		padding-right: 5px
//@keyframes onAutoFillCancel
//	from
//		padding-left: 5px
//	to
//		padding-right: 5px
//
//input:-webkit-autofill
//	// Expose a hook for JavaScript when autofill is shown
//	// JavaScript can capture 'animationstart' events
//	animation-name: onAutoFillStart;
//
//	// Make the background color become yellow really slowly
//	transition: background-color 50000s ease-in-out 0s;
//
//input:not(:-webkit-autofill)
//	// Expose a hook for JS onAutoFillCancel
//	// JavaScript can capture 'animationstart' events
//	animation-name: onAutoFillCancel;
