.header-mobile
	display none
	//position: fixed
	top: 0
	width: 100%
	box-shadow: 0 4px 10px rgba(0,0,0,0.25)
	background-color: #888888
	z-index: 10
	&__top
		display: flex
		justify-content space-between
		align-items center
		grid-gap: 10px
		height: 60px
		width: 100%
		background-color: #333
		padding: 0 25px
		& .header-mobile--left, .header-mobile--right
			width 220px
	&__bottom
		display: flex
		justify-content space-between
		grid-gap: 10px
		height: 60px
		width 100%
		padding 0 25px
		& .header-mobile--left, .header-mobile--right
			width 90px
	&--left, &--center, &--right
		display: flex
		align-items center
		height 100%
	&--center
		justify-content center
	&--right
		justify-content flex-end
		.games__favorite
			position relative
			bottom 3px
	& .profile-loyalty
		& .user-info__profile-name
			display: block;
			max-width: 100px;
			line-height: 1;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			transition: color .2s ease-out;
			font-weight 500
			&:hover
				color $primary-yellow
		&-bar
			width 80px
		&-heading
			font-size 12px
			margin 0
		& .profile-loyalty-inner
			height: 20px

	& .currency, .user__name
		margin 0
