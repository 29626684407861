@media all and (max-width: 980px)
	.header__banner, .header-top__left, .header-top__right
		display none
	.header
		& .container
			padding 0
	.header--left
		width: 100%
		justify-content: space-between

	.welcome-block
		text-align center
		padding-top 80px
	.section--top
		height 300px
		background-size cover
	.mobile-bar
		display flex
	.features__list, .service__list
		grid-template-columns: repeat(2,1fr)

	.page__content
		flex-direction column
	.sidebar
		margin-top 24px
		margin-left 0
		width 100%
