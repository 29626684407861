// -----------------------------------------------
// Module - Header
// -----------------------------------------------
.header
	background-color $color-primary-darker
	&__layout
		display flex
		justify-content space-between
		align-items center
		height 64px
	&--left, &--center, &--right
		display: flex
		align-items center
		height 100%
	&--left
		justify-content: flex-start
		width 100%
	&--right
		justify-content: flex-end
		width auto
		flex-shrink 0
	&-top
		height: 70px
		background-color: rgba(0, 0, 0, 0.7)
		color #FFFFFF
		font-family $font-title
		font-size 20px
		font-weight 600
		line-height 1.2
		text-transform uppercase
		&__left
			& span
				color #d35143
		&__right
			text-align right
			& span
				color #d35143
		& .container
			display flex
			justify-content space-between
			align-items center
		& .logo
			width 150px
			height 72px
			position relative
			& img
				position absolute
				top 10px
	&__banner
		padding 8px 12px
		border: 2px solid #e9af45
		border-radius: 5px
		text-transform uppercase
		font-family $font-title
		color #e9af45
		line-height 1
		font-weight 600