/*
* Лоадер
* Используется при загрузке страницы, при подгрузке игр, бонусов, турниров и в модалке с лоадером
*/

.loader
	background-color var(--background-body)
	position fixed
	top 0
	left 0
	right 0
	bottom 0
	z-index 999
	width: 100%
	height: 100%
	display flex
	justify-content center
	align-items center
	svg
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;

		#infinity-outline
			stroke-dasharray: 500px*0.01, 500px
			stroke-dashoffset: 0
			animation: anim 4000ms linear infinite

		#infinity-bg
			opacity: 0.1

@keyframes anim {
	12.5% {
		stroke-dasharray: 500px*0.14, 500px;
		stroke-dashoffset: -500px*0.11;
		opacity .1
	}
	43.75% {
		stroke-dasharray: 500px*0.35, 500px;
		stroke-dashoffset: -500px*0.35;
		opacity .6
	}
	75.75% {
		stroke-dasharray: 500px*0.5, 500px;
		stroke-dashoffset: -500px*0.55;
		opacity .9
	}
	100% {
		stroke-dasharray: 500px*0.01, 500px;
		stroke-dashoffset: -500px*0.99;
		opacity 1
	}
}
