.article
	display flex
	justify-content center
	align-items center
	width 100%
	&__content
		width 100%
		flex-shrink 0
		p
			font-size: 14px
			line-height: 20px
			margin-bottom 10px
		h2, h3
			text-transform uppercase
			& span
				color: #d35143
		ul, ol, img, span, table, address, em
			margin-bottom: 20px
		address, em
			font-style: italic
		img
			margin-bottom 25px
		a
			text-decoration: underline
			&:hover
				text-decoration: none
		ul, ol
			margin-left: 20px
			padding-left 12px
			font-size 14px
		li
			display: list-item
		hr
			border 1px solid #A6A6A6
			margin 20px 0