// -----------------------------------------------
// Styles - for Pages
// -----------------------------------------------
.page
	&-text, &-contacts
		padding 30px 0
	&__header
		padding-bottom 12px
		border-bottom 1px solid rgba(0, 0, 0, 0.8)
		margin-bottom 16px
	&__title
		width auto
		margin 0
		font-size: 32px
		line-height: 1.2
		text-transform uppercase
		& span
			color #d35143
	&__content
		display flex
		justify-content space-between
		align-items flex-start
		width 100%


