.header-menu
	display: flex
	flex-wrap: nowrap
	height 100%
	color #c9c9c9
	font-family $font-title
	font-size 14px
	font-weight 600
	flex-shrink 0
	&__item
		text-align center
		&-link
			display flex
			align-items center
			transition $t-color
			cursor pointer
			text-transform uppercase
			padding 10px
			height 100%
			&:hover,
			&.active
				background-color #050505
				color #d35143
		& .submenu
			display none
			position absolute
			flex-direction column
			background #2e2e2e
			text-align left
			&__item

				& a:hover
					background-color: #050505;
					color: #d35143;
				& a
					display block
					padding 10px 15px
			&__item
				& a.active
					background-color: #050505;
					color: #d35143;
		&:hover
			.submenu
				display flex
		& .submenu2

			& .submenu__item
				& a
					padding 0 20px 0 30px
					&:hover
						background transparent
						color: #d35143;
				& .active
					background transparent

					color: #d35143;
.header-menu-main
	display flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-start
	height: auto
	width: 40px
	transition: width .2s ease;
	&__item
		position relative
		width: 100%
		color: #707585
		font-weight: 400
		transition: background .2s ease-out;
		cursor: pointer
		margin 6px 0
		&:hover .tooltip
			visibility: visible
			opacity: 1
		&-link
			position relative
			height 40px
			display: flex
			align-items: center
		& i
			position absolute
			width 40px
			height 40px
			font-size 24px
			line-height normal
			transition: all .2s ease
			cursor: pointer
			border-radius: 8px
			color #B3B6BC
		& span
			position relative
			left 50%
			max-width: 0
			opacity 0
			overflow: hidden
			transition opacity .4s ease-out, left .6s cubic-bezier(0.23, 1, 0.32, 1)
		&:hover,
		&.active
			& i
				color: #DA6431