/***
*
* Стилизация input type="checkbox"
*
***/
.checkbox
	text-align left

	input[type="checkbox"]
		display: none
		height: 0
		visibility: hidden

		& + label
			position: relative
			display inline-block
			width auto
			padding-left 28px
			min-height 20px
			font-size: 14px
			line-height: 20px
			cursor pointer
			&::before
				content ''
				position: absolute
				top 50%
				left 0
				width 20px
				height: 20px
				border: 2px solid var(--element-border-color)
				background: var(--background-body)
				box-shadow: var(--box-shadow-inset)
				border-radius: 4px
				transform translateY(-50%)
				transition 0.3s

			&::after
				content ''
				position absolute
				top 50%
				left 4px
				width 12px
				height 12px
				border-radius: 2px
				background: #21e418
				transform: translateY(-50%)
				opacity 0
				outline none
				transition 0.3s
				background: linear-gradient(273.8deg, #D96331 15.88%, #E99018 98.99%)

		//&:checked + label::before
		//	background-color #21E418
		//	border-color #21E418

		&:checked + label::after
			opacity 1

		&:disabled + label
			cursor default

		&:disabled + label:after
			border-color #fff

		&:disabled + label:before
			background-color #A6A6A6
			border-color #A6A6A6

	& a

		&:hover
			color: #e99018
			text-decoration: none

	&--reverted
		input[type="checkbox"]
			& + label
				padding-left 0
				padding-right 35px

				&:before
					right: 0
					left: auto

				&:after
					right: 4px
					left: auto


