/***
*
* Стилизация input type="radio" + списки элементов в форме
*
***/

.radio
	text-align left

	input[type="radio"]
		display none
		height 0
		visibility hidden

		& + label
			position relative
			display inline-block
			width auto
			min-height 20px
			padding-left 30px
			font-size 14px
			line-height 20px
			cursor pointer

			&:before,
			&:after
				content: ""
				position: absolute
			&:before
				width: 20px
				height: 20px
				border 3px solid var(--element-border-color)
				border-radius 50%
				top: 50%
				left: 16px

				box-shadow: var(--box-shadow-inset)
				transform: translateY(-50%)
				z-index 2
			&:after
				width: 8px
				height: 8px
				left: 22px
				top: 50%
				border-radius 50%
				background linear-gradient(273.8deg, #D96331 15.88%, #E99018 98.99%);
				transform: translateY(-50%)
				transition: $t-opacity
				opacity: 0
				z-index 2

		&:checked
			& + label
				&::after
					opacity 1
		&:disabled
			& + label
				cursor default
				&::after
					background  #fff
				&::before
					background #A6A6A6
					border-color #A6A6A6
	& a
		color #FFD000
		margin 0 5px
		&:hover
			color #FFFFFF

	&--reverted
		input[type="radio"]
			& + label
				padding-left 0
				padding-right 35px

				&::before
					right 0
					left auto

				&::after
					right 4px
					left auto

	&__list
		display: flex
		flex-direction: row
		justify-content: center
		align-items: center
		flex-wrap: wrap

		.radio
			width auto
			margin 0px

	&__list--vertical
		& input[type="radio"]
			& + label
				width auto
				padding 30px 0px 0

				&::before
					left 50%
					top 0
					transform translateX(-50%)

				&::after
					top 6px
					left 50%
					transform translateX(-50%)


