.service
    &__list
        display grid
        grid-template-columns repeat(4, 1fr)
        grid-gap 32px
        width 100%
    &__item
        padding: 15px 10px
        border: 1px solid rgba(255, 255, 255, 0.7)
        background-color: #c9c9c9
        text-align center
        border-radius: 8px;
        & h3
            font-size 20px
            text-transform uppercase
        & p
            margin-bottom 0
        & img
            margin-bottom 10px


