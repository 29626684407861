/***
* Резиновое меню
* Можно применить к любому горизонтальному меню
***/
.menu
	flex-wrap wrap

	&-dd
		&__item
			position relative

			display none

			order 99

			&::after
				display none

			&--active
				display flex

		&__button
			position relative

			display flex
			align-items center

			width 100%
			min-width 85px
			height 45px
			padding-left 10px

			&&
				padding-right 35px

				border 2px solid #ff6b00
				border-radius 30px

			&:hover
				box-shadow 0 0 10px 1px #ff4d00

			&::after,
			&::before
				content: ''

				position absolute
				right 6px
				top 50%

				height 2px
				width 10px

				background #fff

				box-sizing border-box
				backface-visibility hidden
				transition-property transform
				transition-duration .2s

			&::before
				right 12px

				transform rotate(45deg)

			&::after
				transform rotate(-45deg)

		&__item.open
			& ^[-1]
				/ &__dropdown
					max-height 500px

					visibility visible

					& li
						display block

						margin-right 0

				/ &__button
					&::before
						transform rotate(-45deg)

					&::after
						transform rotate(45deg)

		&__dropdown
			position absolute
			top 50px
			left 0
			z-index 2

			//min-width 220px
			padding 12px
			max-height 0

			background #3D4047
			border-radius 10px

			overflow hidden
			visibility hidden
			transition max-height .4s ease-out

			& li
				&:not(:last-of-type)
					margin-bottom 15px

				&::after
					display none

				a
					&:hover
						color #ff7a00