// -----------------------------------------------
// Base
// -----------------------------------------------
body
	transition color .4s
	background-color: #d6d6d6
	&.fixed
		overflow: hidden
		height: 100vh
	&.blur
		& .main
			filter blur(3px)
			transition all .3s ease-out
.helper
	display flex
	flex-direction column
	justify-content space-between
	min-height 100vh
	transition background-color .4s
	background-color $background-body
.section
	width 100%
	padding-top 30px
	padding-bottom 50px
	&__title
		width 100%
		margin-top 20px
		margin-bottom 24px
		font-size 38px
		transition color .4s
		line-height 1.2
		text-transform uppercase
		text-align center
		& span
			color: #d35143
	&--top
		height 500px
		padding 0
		background-image url('../img/backgrounds/header01.webp')
		background-position center
	&--service
		background-color #FFFFFF
a, img
	-webkit-user-drag none
	-khtml-user-drag none
	-moz-user-drag none
	-o-user-drag none
	user-drag none
a
	transition all .5s