@media all and (max-width: 768px)
    .header
        position fixed
        top 0
        left 0
        right 0
    .helper
        margin-top 64px
    .mobile-menu__toggle
        display flex
    .header-menu
        position absolute
        top -100%
        left 0
        right 0
        display none
        flex-direction column
        align-items center
        height 100vh
        background-color: #242424
        &.opened
            top 64px
            display flex
    .header-menu__item
        width 100%
        &-link
            height 42px

    .button.desktop
        display none
    .button.mobile
        display inline-flex
    .header-menu__item .submenu
        display flex
        width: 100%;
        text-align: left;
        position: relative;
        & a
            position relative
            padding-left 12px
            &:before
                content '-'
                margin-right 10px
