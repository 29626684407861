@media all and (max-width: 580px)
	.sidebar__wrapper
		grid-template-columns 1fr
	.games-type-menu__wrapper
		padding-top unset
	/*
		Transactions
	*/
	.transactions
		&__item
			flex-direction column
			align-items flex-start
			&-description
				width 100%
				text-align left

	// ----- MSG page -------------------------------------------------------
	.msg
		&__list
			grid-template-columns: repeat(1,1fr)

	.msg__item-title
		min-height unset
		max-height unset
		height auto


	.profile-accounts__list
		grid-template-columns 1fr

@media all and (max-width: 600px)
	.sportbook .spt-bet-slip [data-editor-id="betslipMobileButtonGradient"]
		bottom: 50px
		position relative