.header-logo
	width 100%
	height 100%
	display flex
	justify-content center
	align-items center
	transition: unset
	&__wrapper
		height 100%
		max-width 170px
		padding-top 10px
		flex-shrink 0
		transition: filter .2s ease-out;
		&:hover
			filter: drop-shadow(0px 0px 10px #9b42f4);
			//background: radial-gradient(50% 50% at 50% 50%, #9B42F4 0%, #2A2B30 100%);
