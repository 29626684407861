.lang
	flex-shrink: 0
	width: 85px
	height: 30px
	margin-left: auto
	margin-right 12px
	font-family $font-title
	font-weight 600
	&-select
		position relative
		display flex
		flex-direction column
		height 30px
		color black
		border-radius 8px
		cursor pointer
		overflow hidden
		transition all 150ms ease-in-out
		text-transform uppercase
		&:before, &:after
			content: '';
			display: block;
			box-sizing: border-box;
			position: absolute;
			right: 8px;
			top: 14px
			height: 2px;
			width: 10px;
			background: #c9c9c9
			backface-visibility: hidden;
			transition-property: transform;
			transition-duration: .2s;
		&:before
			transform: rotate(45deg);
			right: 14px;
		&:after
			transform: rotate(-45deg);
		&.open
			&:before
				transform: rotate(-45deg);
			&:after
				transform: rotate(45deg);
		&__item
			display flex
			align-items center
			order 2
			//user-select none
			z-index 1
			/* choosen language */
			&.active
				order 1
				pointer-events none

		/* base item styles */
		&__item
			min-height: 30px

			&-link
				display: flex
				width: 100%

				&:hover
					& .lang-select__item-text
						color: #FFFFFF
			img
				display block
				height 20px
				width 20px
				border-radius 12px
				box-sizing border-box
				overflow hidden
			&-text
				display flex
				justify-content center
				align-items center
				width 20px
				height 20px
				margin-left 5px
				margin-right 5px
				font-size 14px
				color: #c9c9c9
				font-weight: 600
				transition: all .25s ease-in
		/* when select is open */
		&.open
			height: auto
			background #050505
			transition: all .15s ease-out
			z-index: 2

