.select
	/*
	*	Стилизация дефолтной версии селекта
	*	Используется в основных формах
	*/
	.selectric
		background var(--background-body)
		border 1px solid var(--element-border-color)
		box-sizing border-box
		box-shadow var(--box-shadow-inset)
		border-radius 8px
		transition .4s

		&-open
			.selectric
				& .label
					opacity: 1

		&-wrapper
			& .label
				max-width 95%
				padding 10px 38px 10px 16px

				font-size 14px
				font-weight normal
				line-height 18px
				color #707585

				//opacity .75
				transition $t-opacity

			^[-1]
				/ &--placeholder
					.label
						color #B3B6BC

		&-items
			margin-top 4px

			font-size 14px
			color #979EB7

			background var(--background-body)
			box-shadow var(--box-shadow-simle)
			border-radius 8px
			border none

			& li
				padding 10px

				&.selected
					color #2F384E

				&:hover
					background #F6F7FC

		&__button
			top 10px
			right 10px

			width 20px
			height 20px

			&::before
				content ''

				width 0
				height 0

				border-top 6px solid #707585
				border-left 4px solid transparent
				border-right 4px solid transparent
				box-shadow inset 0.4px 0 1px rgba(0, 0, 0, 0.3)

		&-disabled
			opacity: 1

			& .selectric
				border-color: #3D4047

				& .label
					color #B3B6BC

				&__button
					&::before
						border-top-color #B3B6BC

		// цвет текста в селекте, когда он открыт
		//&-open
		//	& .label
		//		color #FFD000

	/*
	*	Стилизация упрощенной версии селекта
	*	Используется в выборе счёта, провайдера, на странице с подробностями турнира
	*/
	&--v2
		.label-input
			padding-left 8px
			margin-bottom 0

		.form__input&
			max-width 100%
			margin-bottom 0

			font-family $font-primary
			& .input__wrapper
				width auto

		.selectric
			display flex
			align-items center

			padding-right 20px

			background transparent
			box-shadow none
			border none

			&-wrapper
				width auto
				max-width 100%

			& .label
				max-width 100%
				padding 0 4px 0 8px

				font-size 14px
				line-height 30px
				color #fff

				opacity 1

			&__button
				display: flex;
				align-items: center;
				justify-content: center;
				color: transparent;
				text-align: center;
				padding 0
				text-indent: -999px;
				height 100%
				width 16px
				right 0
				top 0
				&::before,
				&::after
					content: '';
					display: block;
					box-sizing: border-box;
					position: absolute;
					right: 8px;
					top: 50%;
					height: 2px;
					width: 8px;
					margin-top -1px
					background: #707585
					backface-visibility: hidden;
					transition-property: transform;
					transition-duration: .2s;
					border none
				&:before
					transform: rotate(45deg)
					right: 4px;
				&:after
					transform: rotate(-45deg)
					right 0

			&-items
				li
					min-height 24px
					font-weight: normal
					padding: 8px 16px
					white-space nowrap

			&-open
				& .selectric
					background-color unset
					border-radius unset

					&__button
						&:before
							transform: rotate(-45deg)
						&:after
							transform: rotate(45deg)

					&-items
						min-width 140px
						margin-top 5px
						border none
						border-radius 8px
						& li
							&:hover
								color: #2f384e

								background transparent

	&--inline
		width auto

		.selectric
			display inline-flex

	&--tabs
		width auto
		display flex
		margin-bottom 60px
		&&
			letter-spacing 0

		.selectric
			display none

			&-items
				position static
				display block
				width auto !important
				min-width 100%
				padding 4px
				margin-top 0
				background: var(--background-body)
				box-shadow: var(--box-shadow-inset)
				border-radius: 6px
				overflow hidden
				ul
					display flex
					align-items center
					justify-content space-around
					min-height 24px
					max-width 100%
					width 100%
					vertical-align: middle
				li
					position relative
					display flex
					justify-content center
					align-items center
					width auto
					padding 4px 6px
					margin 0
					font-weight: 500
					font-size: 12px
					line-height: 16px
					text-align: center
					text-transform: uppercase;
					color: #707585
					transition: $t-color
					cursor pointer
					&:hover
						background unset
					&.selected
						display flex !important
						cursor default
						color #fff
						background: linear-gradient(273.8deg,#d96331 15.88%,#e99018 98.99%)
						border-radius: 4px

	&--transformed
		max-width 365px
		padding 0
		margin 0 auto
		.tabs__select
			display none
		& .selectric
			box-shadow: var(--box-shadow-inset)
			&-open
				.selectric
					border-radius 8px
			&__button
				right 10px
			& .label
				color #707585
				width 100%
				font-weight 500
			&-wrapper
				min-width 200px
			&-items
				& ul
					min-height 10px
