// -----------------------------------------------
// Typography
// -----------------------------------------------
html
	font-size: $font-size-default
	font-family: $font-primary

h1, h2, h3, h4, h5
	line-height: normal
	user-select none
	margin-bottom 12px
	font-family $font-title
h1
	display: block
	width: 100%
	font-size: 60px
	font-weight: 700

h2
	display: block
	width: 100%
	font-size: 30px
	font-weight: 700

h3
	font-size: 24px
	font-weight: 700

p
	margin-bottom: 20px
li, a
	user-select none
.text__link
	text-decoration: underline
	color: #b0a39b
	&:hover
		color #ff6b00