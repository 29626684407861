@media all and (max-width: 350px)
	.wheel-with-games
		&__wrapper
			padding 0 16px
			grid-template-columns repeat(2, 136px)
			height 215px
			//height 115px
			overflow hidden

		&__inner
			height 230px
			//height 115px
	.modal
		&__title
			padding 10px 50px 10px 16px
		&__buttons
			padding 10px 16px
