// -----------------------------------------------
// Component - Modal
// -----------------------------------------------
.mfp
	&-content
		& .wrapping
			display: flex
			flex-direction: column
			align-items: center
			width 560px
			max-width 100%
			box-sizing: border-box
			outline none
			& form
				width 100%
			& h3
				font-size: 36px
				line-height: 46px
				color: var(--title-font-color)
				margin-bottom 16px
				padding-right 56px
			& h4
				font-size 24px
				font-weight 400
			& p
				&:last-child
					margin-bottom 0
				& span
					color #23de1a
			& .modal__img

				width: 160px;
				height: 160px;
				object-fit: scale-down;
			& .modal__qr
				position absolute
				width 80px
				height 80px
				top 40px
				left 0
				right 0
				margin auto
			& .radio__list--vertical
				justify-content flex-start
				margin 0

				.radio
					margin-bottom 20px
					width 50px
					text-align center

			& .radio__item-label
				text-transform uppercase

			&
				input[type='text'],
				input[type='password'],
				input[type='email'],
				input[type='tel'],
				input[type='search'],
				textarea
					background-color transparent
			& .deposit__description
				display flex
				align-items flex-end
				padding-bottom: 8px
				color: #B0A39B
				& span
					display block
					flex-shrink 0
					font-size: 16px
					line-height: 20px
					text-align: right
					color: #2F384E
			& .form__section--submit
				margin-top 0

			& .form
				p
					margin-bottom 20px
					line-height: 24px
					color: #2F384E
					justify-content space-between
					border-bottom 1px solid var(--border-color)

				&__section
					&--submit
						& .button + .button
							margin-top 10px
			&-custom
				.mfp-close
					font-size 40px
					line-height 20px
				.modal
					&__content
						padding 0
					&__title
						margin 0
						padding 10px 50px 10px 20px
						box-shadow 2px 2px 16px rgba(33, 36, 49, 0.16)
						h1, h2, h3
							margin 0
							text-transform none
					&__buttons
						justify-content center
						padding 4px 20px
						box-shadow 2px 2px 16px rgba(33, 36, 49, 0.16)

.modal
	&__content
		position relative
		width 100%
		padding: 32px
		background: var(--background-body)
		border-radius: 12px
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.45)
		background-color #d6d6d6

	&__small-text
		font-size 12px
		color #B0A39B
		margin-top 20px
	&__list
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(220px, auto))
		grid-gap: 25px

		& li
			display: flex
			justify-content: center
			width 100%
			& ul
				width 100%
			& a
				margin 5px 0
	&__buttons
		display flex
		flex-wrap wrap
		width 100%
		&:not(:last-child)
			margin-bottom 20px
		& .button
			&:not(:last-child)
				margin-right 12px
	&__params
		margin-bottom 32px
		color: var(--title-font-color)
		border-bottom 1px solid var(--border-color)

		li
			display flex
			justify-content space-between
			margin-bottom 8px
			span
				font-weight 500

	&__tournament
		&__description
			margin-bottom 20px
			line-height: 24px
			color: var(--title-font-color)
			justify-content space-between
			border-bottom 1px solid var(--border-color)
			padding-bottom 8px
			& p
				margin-bottom 10px
			& span
				display block
				font-size: 16px
				line-height: 20px
				color: var(--title-font-color)
		&--error
			color #FF0000
			margin-bottom 20px
			line-height 1.5
			& span
				display block
				width 100%
				text-align center




.modal__crypto
	display flex
	flex-direction column
	align-items flex-start
	& .form--crypto
		display flex
		align-items center
		margin-top 24px
		& .form__input
			margin-bottom 0
			margin-right 16px


.modal
	&__qr
		background: var(--background-body)
		box-shadow: var(--box-shadow)
		border-radius: 12px
		margin-top: 24px
		overflow: hidden
	&__title
		display flex
		align-items center
		width auto
		font-size 44px
		transition color .4s
		color var(--title-font-color)
		line-height 52px
		padding-right 50px
		text-transform uppercase
		margin-bottom 24px
	&-transactions
		width: 100%
		height: 100%
		padding: 32px
		& .mfp-close
			top: 40px
			right: 40px
			padding: 0
			width: 40px
			height: 40px
			border-radius 6px
			color: var(--title-font-color)
			background-color var(--button-bg)
			box-shadow: var(--box-shadow)
			&:hover
				background var(--button-bg-hover)
			&:active
				box-shadow: var(--box-shadow-inset)
		& .form__section--submit
			padding-top 24px
			border-top: 1px solid var(--border-color)
/* --- crypto modals -------------------------------------------------------- */
.mfp-content .wrapping .modal__crypto

	& .form__section--submit
		flex-wrap wrap

	&-heading
		text-align: left
		font-weight: 900
		font-size: 18px
		line-height: 21px
		text-transform: uppercase
		margin-top: -20px
		margin-bottom: 14px

	&-code
		display: flex
		flex-direction: column
		width: 100%
		margin-bottom: 16px

		& .form__input
			margin-bottom: 20px

			& input[type='text']
				padding-right: 40px

			&:after
				content: ""
				width: 22px
				height: 22px
				background-image: url("../img/elements/modals/qr-code-icon.svg")
				background-position: center
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: 50%
				right: 12px
				transform: translateY(-50%)

		& .button
			margin-left: auto
			margin-right: auto

	&-text + .modal__crypto-code .form__input
		margin-bottom: 30px

	&-row
		width: 100%
		display: flex
		justify-content: space-between
		align-items: center
		margin-bottom: 10px

		& span
			font-size: 14px
			line-height: 1.5

			&:first-child
				color: #B0A39B
				text-align: left
				white-space: nowrap

			&:last-child
				color: white
				font-weight: bold
				text-align: right
				word-break: break-word

	&-row + .form__section--submit
		margin-top: 20px

	&-currency
		width: 100%
		display: flex
		justify-content: space-between
		align-items: center
		margin-top: -14px
		margin-bottom: 20px

		& .currency
			width: 82px
			margin: 0 10px 0 0

			@media (max-width: 980px)
				display: block

			& .selectric
				height: 27px

				& .label
					text-transform: uppercase
					padding-left: 10px
					padding-right: 0

					@media (max-width: 768px)
						font-size: 14px

					&:before
						display: none

			& .selectric-open .selectric-items
				min-width: 82px

		&-sum
			display: flex
			justify-content: flex-end
			align-items: center
			text-align: right

			& span
				font-weight: bold
				font-size: 14px
				line-height: 1.5

	&-text
		margin-top: 6px
		margin-bottom: 20px

		& p
			font-size: 14px
			line-height: 1.5
			margin-bottom: 8px

	&-plate-text
		margin-bottom: 20px
		line-height: 24px
		color: #2f384e
		justify-content: space-between
		border-bottom: 1px solid var(--border-color)

		& > p
			font-size: 14px
			line-height: 1.5
			text-align: left

.mfp-inline-holder .mfp-content
	& .mfp-close
		top: 40px
		right: 40px
		padding: 0
		width: 40px
		height: 40px
		border-radius 6px
		color: var(--title-font-color)
		background-color var(--button-bg)
		box-shadow: var(--box-shadow)
		&:hover
			background var(--button-bg-hover)
		&:active
			box-shadow: var(--box-shadow-inset)
.mfp-inline-holder
	& .modal__content.modal__avatar
		display: flex
		flex-direction: column
		width 480px
		max-width 100%
		& .modal__avatar-wrapper
			margin auto
		& p
			margin-bottom 0
			text-align center
		& .mfp-close
			top: 12px
			right: 12px
			padding: 0
			width: 24px
			height: 24px
			border-radius unset
			color: var(--title-font-color)
			background-color unset
			box-shadow: unset
			&:hover
				background unset
				color #b3b6bc
			&:active
				box-shadow: unset
/*
====== Zoom effect ======
*/

.mfp-zoom-in
	.mfp-content
		opacity 0
		transition all 0.2s ease-in-out
		transform scale(0.4)
	&.mfp-bg
		opacity 0
		transition all 0.3s ease-out
	/* animate in */
	&.mfp-ready
		.mfp-content
			transition all 0.2s ease-in-out
			opacity 1
			transform scale(1)
		&.mfp-bg
			opacity 0.8
	/* animate out */
	&.mfp-removing
		.mfp-content
			transform scale(0.8)
			opacity 0
		&.mfp-bg
			opacity 0
/*
====== Move-from-top effect ======
*/
.mfp-move-from-top
	&.mfp-wrap
		top 70px
	.mfp-content
		vertical-align top
		max-width 100%
	/* start state */
	.mfp-content
		opacity 0
		transition: all 0.2s
		transform translateY(-100%)
	&.mfp-bg
		z-index 9
		opacity: 0
	/* animate in */
	&.mfp-ready
		.mfp-content
			transition all .4s
			opacity 1
			transform translateY(0)
	/* animate out */
	&.mfp-removing
		transition all 2s
		.mfp-content
			transform translateY(-100%)
			opacity 0
			transition all 2s
		&.mfp-bg
			opacity 0
/*
====== Move-from-right effect ======
*/

.user-navigation, .sidebar-providers, .lang-menu
	& .mfp-close
		top: 40px
		right: 32px !important
		padding: 0
		width: 40px
		height: 40px
		border-radius 6px
		color: var(--title-font-color)
		background-color var(--button-bg)
		box-shadow: var(--box-shadow)
		&:hover
			background var(--button-bg-hover)
		&:active
			box-shadow: var(--box-shadow-inset)
	&.mfp-wrap
		left: auto
		right: 0
		width 100%
		max-width 540px
		background: var(--background-body)
	.mfp-content
		width 100%
		height 100%
	/* start state */
	&.mfp-wrap
		opacity 0
		transition: all .6s
		transform translateX(100%)
	&.mfp-bg
		background: #2F384E
		opacity: 0.68
		transition opacity .2s
	/* animate in */
	&.mfp-ready
		&.mfp-wrap
			transition all .4s
			opacity 1
			transform translateX(0)
	/* animate out */
	&.mfp-removing
		transition all 1s
		&.mfp-wrap
			transform translateX(100%)
			opacity 0
			transition all 1s
		&.mfp-bg
			opacity 0
.sidebar-providers.mfp-wrap
	max-width 55%
/*
====== Move-from-right effect ======
*/
.mfp-move-from-right
	&.mfp-wrap
		left: auto
		right: 0
		width 100%
		max-width 1116px
		background: var(--background-body)
	.mfp-content
		width 100%
		height 100%
	/* start state */
	&.mfp-wrap
		opacity 0
		transition: all 0.4s
		transform translateX(100%)
	&.mfp-bg
		background: #2F384E
		opacity: 0.68
		transition opacity .2s
	/* animate in */
	&.mfp-ready
		&.mfp-wrap
			transition all .4s
			opacity 1
			transform translateX(0)
	/* animate out */
	&.mfp-removing
		transition all 1s
		&.mfp-wrap
			transform translateX(100%)
			opacity 0
			transition all 1s
		&.mfp-bg
			opacity 0
.avatar-upload-modal
	padding 60px 20px 20px
	background: #EEF0F6
	border-radius: 12px
	box-shadow: 0 4px 30px rgba(0,0,0,0.45)

#welcome_modal
	& .mfp-close
		top 20px
		right 20px

