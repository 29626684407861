// -----------------------------------------------
// Container
// -----------------------------------------------

// Generic Variables
4k = 'all and (max-width: 3840px)'
xxl = 'all and (max-width: 1920px)'
mxl = 'all and (max-width: 1760px)'
sxl = 'all and (max-width: 1580px)'
ssxl = 'all and (max-width: 1440px)'
xl = 'all and (max-width: 1280px)'
lg = 'all and (max-width : 1024px)'
md = 'all and (max-width : 768px)'
sm = 'all and (max-width : 640px)'
xs = 'all and (max-width : 480px)'
xxs = 'all and (max-width : 320px)'
t = '(pointer:coarse)'

// -----------------------------------------------
.container
	margin: 0 auto
	padding: 0 12px
	max-width: 960px
	width: 100%
