// -----------------------------------------------
// Animations
// -----------------------------------------------
@keyframes spin
	100%
		transform: rotate(360deg)

//@keyframes pulse
//	from
//		filter: hue-rotate(-60deg)
//	to
//		filter: hue-rotate(0deg)

@keyframes shaking
	from
		padding-left: 5px
	to
		padding-right: 5px

@keyframes twist
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)
@keyframes open
	0%
		display: block
		opacity: 0
		visibility: hidden

	100%
		display: block
		opacity: 1
		visibility: visible

@keyframes pulse
	0%
		box-shadow: 0 0 0 0 rgba(0, 187, 19, 0.4);

	70%
		box-shadow: 0 0 0 10px rgba(0, 187, 19 0);

	100%
		box-shadow: 0 0 0 0 rgba(0, 187, 19 0);

@keyframes marquee
	0%
		left 0
	100%
		left -100%
@keyframes marquee
	 0%
		left 0
	 100%
		left -100%

.winners--top, .jackpots--top
	position absolute
	top -100px
	display: block
	width: 100%
	height: 100px
	margin: 0
.winners--bottom
	position absolute
	bottom -100px
	display: block
	width: 100%
	height: 100px
	margin: 0
	transform: rotateX(180deg)
.jackpots--top .parallax > use{
	animation:move-forever 12s linear infinite;
	animation-delay:-4s; animation-duration:3s
}

.parallax > use{
	animation:move-forever 12s linear infinite;
	&:nth-child(1){animation-delay:-2s;}
	&:nth-child(2){animation-delay:-4s; animation-duration:3s}
}


@keyframes move-forever{
	0%{transform: translate(-90px , 0%)}
	100%{transform: translate(85px , 0%)}
}
