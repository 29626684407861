input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
input[type='search'],
input[type='number'],
textarea
	width 100%
	height 40px
	padding 8px 16px
	font-family $font-primary
	font-size 14px
	font-weight normal
	line-height 20px
	color #707585
	background transparent
	border 2px solid #e9af45
	border-radius 4px
	box-sizing border-box
	transition all .4s
	flex-shrink 0
	&::placeholder
		color #B3B6BC
		transition $t-opacity
	&:focus::placeholder
		opacity 0
	&:focus
		border 2px solid #e99018

input[type='password']
	padding-right 40px
	&::placeholder
		letter-spacing normal
	&:focus::placeholder
		opacity: 0

textarea
	width 100%
	height 96px
	padding: 8px 16px
	line-height 1.5
	overflow auto
	box-sizing border-box
	resize none
	/***
	* Обнуление автозаполнения инпутов в современных браузерах
	***/
	&:-webkit-autofill
		-webkit-animation-name autofill-fix
		-webkit-animation-fill-mode both
	@-webkit-keyframes autofill-fix
		to
			color #fff
			background #c4c4c4
	/***
	* Обнуление автозаполнения инпутов в edge
	***/
	&[class*="edge-auto"]
		color #ffffff !important
		background-color #c4c4c4 !important
		&:focus
			color #ffffff !important
			background-color #c4c4c4 !important

// -----------------------------------------------------------------------------
// Component - Form Input ------------------------------------------------------
// -----------------------------------------------------------------------------
.input__wrapper
	width 100%
.form__input
	width 100%
	margin-bottom 24px
	position relative
	/* ----- error ------------------------------------------------------------ */
	&.error
		margin-bottom 12px
		& input
			border: 1px solid #BD4545
		& .form__error-message
			display block

	&--checkbox
		& .input__wrapper
			width 100%
	/* ----- success ---------------------------------------------------------- */
	&.success
		& .form__success-message
			display block

	/* ----- disabled --------------------------------------------------------- */
	& input[type='text']:disabled,
	& input[type='password']:disabled,
	& input[type='email']:disabled,
	& input[type='tel']:disabled,
	& textarea:disabled
		color #3D4047
		border-color #3D4047
		cursor default


//Input Animation Styles
.form__input, .captcha__img
	& .label-input
		display inline-block
		font-size 14px
		line-height: 20px
		font-family 'Oswald',sans-serif
		cursor text
		margin-bottom 8px

.form__input--captcha.error
	& .label-input
		bottom -20px
.form__input--textarea
	&.animation .label-input
		transform: translateY(-125px)
.form__input--phone, .form__input--tel
	& .iti
		max-width 300px

.form__input--controls
	display flex
	align-items center
	justify-content space-between
	margin-bottom 24px

	& .form__input
		margin-bottom 0

	& .forgot-link
		font-size 14px
		line-height: 20px
		color: #e99018
		flex-shrink 0
		&:hover
			color #ff6b00

.form__input.textarea.animation .label-input
	transform: translateY(-120px)
