// -----------------------------------------------
// Module - Footer
// -----------------------------------------------

.footer
	background-color $color-primary-darker
	&__layout
		display: flex
		flex-direction: column
		align-items: center
		width 100%

	&__copyright
		display flex
		justify-content space-between
		align-items center
		padding: 12px 0
		& p
			margin-bottom: 0
		& a
			text-decoration: underline
			cursor: pointer
			&:hover
				text-decoration: none
		&--text
			display flex
			justify-content space-between
			align-items center
			font-size 12px
			color #FFFFFF
			font-family $font-title
			text-transform uppercase



