.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade-
	color: #dedede

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover
	color: #c5c5c5

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade-
	color: #dedede

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade-
	background: #a2ddf6
	color: white

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade-
	background: #8ad5f4

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade-
	background-color: rgba(92, 196, 239, 0.1)
	color: #cccccc

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade-
	background-color: rgba(92, 196, 239, 0.2)

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty
	border: none
	background: none

/* -------------------------------------------------
 Datepicker cells
------------------------------------------------- */
.datepicker--cells
	display: -webkit-flex
	display: -ms-flexbox
	display: flex
	flex-wrap: wrap
	-webkit-flex-wrap: wrap
	-ms-flex-wrap: wrap

.datepicker--cell
	position: relative
	z-index: 1
	display: -webkit-flex
	display: -ms-flexbox
	display: flex
	justify-content: center
	align-items: center
	box-sizing: border-box
	height: 32px
	border-radius: 4px
	cursor: pointer
	-webkit-align-items: center
	-ms-flex-align: center
	-webkit-justify-content: center
	-ms-flex-pack: center

.datepicker--cell.-focus-
	background: #f0f0f0

.datepicker--cell.-current-
	color: #4EB5E6

.datepicker--cell.-current-.-focus-
	color: #4a4a4a

.datepicker--cell.-current-.-in-range-
	color: #4EB5E6

.datepicker--cell.-in-range-
	border-radius: 0
	background: rgba(92, 196, 239, 0.1)
	color: #4a4a4a

.datepicker--cell.-in-range-.-focus-
	background-color: rgba(92, 196, 239, 0.2)

.datepicker--cell.-disabled-
	color: #aeaeae
	cursor: default

.datepicker--cell.-disabled-.-focus-
	color: #aeaeae

.datepicker--cell.-disabled-.-in-range-
	color: #a1a1a1

.datepicker--cell.-disabled-.-current-.-focus-
	color: #aeaeae

.datepicker--cell.-range-from-
	border: 1px solid rgba(92, 196, 239, 0.5)
	border-radius: 4px 0 0 4px
	background-color: rgba(92, 196, 239, 0.1)

.datepicker--cell.-range-to-
	border: 1px solid rgba(92, 196, 239, 0.5)
	border-radius: 0 4px 4px 0
	background-color: rgba(92, 196, 239, 0.1)

.datepicker--cell.-range-from-.-range-to-
	border-radius: 4px

.datepicker--cell.-selected-
	border: none
	background: #5cc4ef
	color: white

.datepicker--cell.-selected-.-current-
	background: #5cc4ef
	color: white

.datepicker--cell.-selected-.-focus-
	background: #45bced

.datepicker--cell:empty
	cursor: default

.datepicker--days-names
	display: -webkit-flex
	display: -ms-flexbox
	display: flex
	flex-wrap: wrap
	margin: 8px 0 3px
	-webkit-flex-wrap: wrap
	-ms-flex-wrap: wrap

.datepicker--day-name
	display: -webkit-flex
	display: -ms-flexbox
	display: flex
	flex: 1
	justify-content: center
	align-items: center
	color: #FF9A19
	text-align: center
	text-transform: uppercase
	font-size: 0.8em
	-webkit-align-items: center
	-ms-flex-align: center
	-webkit-justify-content: center
	-ms-flex-pack: center
	-webkit-flex: 1
	-ms-flex: 1

.datepicker--cell-day
	width: 14.28571%

.datepicker--cells-months
	height: 170px

.datepicker--cell-month
	width: 33.33%
	height: 25%

.datepicker--years
	height: 170px

.datepicker--cells-years
	height: 170px

.datepicker--cell-year
	width: 25%
	height: 33.33%

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade-
	color: #dedede

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover
	color: #c5c5c5

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade-
	color: #dedede

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade-
	background: #a2ddf6
	color: white

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade-
	background: #8ad5f4

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade-
	background-color: rgba(92, 196, 239, 0.1)
	color: #cccccc

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade-
	background-color: rgba(92, 196, 239, 0.2)

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty
	border: none
	background: none

/* -------------------------------------------------
 Datepicker
------------------------------------------------- */
.datepickers-container
	position: absolute
	top: 0
	left: 0
	z-index: 1255

@media print
	.datepickers-container
		display: none

.datepicker
	position: absolute
	left: -100000px
	z-index: 100
	box-sizing: content-box
	width: 250px
	border: 1px solid #dbdbdb
	border-radius: 4px
	background: white
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15)
	color: #4a4a4a
	font-size: 14px
	font-family: Tahoma, sans-serif
	opacity: 0
	transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease
	transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s
	transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease

.datepicker.-from-top-
	transform: translateY(-8px)
	-webkit-transform: translateY(-8px)

.datepicker.-from-right-
	transform: translateX(8px)
	-webkit-transform: translateX(8px)

.datepicker.-from-bottom-
	transform: translateY(8px)
	-webkit-transform: translateY(8px)

.datepicker.-from-left-
	transform: translateX(-8px)
	-webkit-transform: translateX(-8px)

.datepicker.active
	opacity: 1
	transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease
	transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s
	transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease
	transform: translate(0)
	-webkit-transform: translate(0)

.datepicker-inline .datepicker
	position: static
	right: auto
	left: auto
	border-color: #d7d7d7
	box-shadow: none
	opacity: 1
	transform: none
	-webkit-transform: none

.datepicker-inline .datepicker--pointer
	display: none

.datepicker--content
	box-sizing: content-box
	padding: 4px

.-only-timepicker- .datepicker--content
	display: none

.datepicker--pointer
	position: absolute
	z-index: -1
	width: 10px
	height: 10px
	border-top: 1px solid #dbdbdb
	border-right: 1px solid #dbdbdb
	background: white

.-top-left- .datepicker--pointer, .-top-center- .datepicker--pointer, .-top-right- .datepicker--pointer
	top: calc(100% - 4px)
	transform: rotate(135deg)
	-webkit-transform: rotate(135deg)

.-right-top- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-bottom- .datepicker--pointer
	right: calc(100% - 4px)
	transform: rotate(225deg)
	-webkit-transform: rotate(225deg)

.-bottom-left- .datepicker--pointer, .-bottom-center- .datepicker--pointer, .-bottom-right- .datepicker--pointer
	bottom: calc(100% - 4px)
	transform: rotate(315deg)
	-webkit-transform: rotate(315deg)

.-left-top- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-bottom- .datepicker--pointer
	left: calc(100% - 4px)
	transform: rotate(45deg)
	-webkit-transform: rotate(45deg)

.-top-left- .datepicker--pointer, .-bottom-left- .datepicker--pointer
	left: 10px

.-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer
	right: 10px

.-top-center- .datepicker--pointer, .-bottom-center- .datepicker--pointer
	left: calc(50% - 10px / 2)

.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer
	top: 10px

.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer
	bottom: 10px

.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer
	top: calc(50% - 10px / 2)

.datepicker--body
	display: none

.datepicker--body.active
	display: block

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade-
	color: #dedede

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover
	color: #c5c5c5

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade-
	color: #dedede

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade-
	background: #a2ddf6
	color: white

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade-
	background: #8ad5f4

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade-
	background-color: rgba(92, 196, 239, 0.1)
	color: #cccccc

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade-
	background-color: rgba(92, 196, 239, 0.2)

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty
	border: none
	background: none

/* -------------------------------------------------
 Navigation
------------------------------------------------- */
.datepicker--nav
	display: -webkit-flex
	display: -ms-flexbox
	display: flex
	justify-content: space-between
	padding: 4px
	min-height: 32px
	border-bottom: 1px solid #efefef
	-webkit-justify-content: space-between
	-ms-flex-pack: justify

.-only-timepicker- .datepicker--nav
	display: none

.datepicker--nav-title, .datepicker--nav-action
	display: -webkit-flex
	display: -ms-flexbox
	display: flex
	justify-content: center
	align-items: center
	cursor: pointer
	-webkit-align-items: center
	-ms-flex-align: center
	-webkit-justify-content: center
	-ms-flex-pack: center

.datepicker--nav-action
	width: 32px
	border-radius: 4px
	user-select: none
	-webkit-user-select: none
	-moz-user-select: none
	-ms-user-select: none

.datepicker--nav-action:hover
	background: #f0f0f0

.datepicker--nav-action.-disabled-
	visibility: hidden

.datepicker--nav-action svg
	width: 32px
	height: 32px

.datepicker--nav-action path
	fill: none
	stroke: #9c9c9c
	stroke-width: 2px

.datepicker--nav-title
	padding: 0 8px
	border-radius: 4px

.datepicker--nav-title i
	margin-left: 5px
	color: #9c9c9c
	font-style: normal

.datepicker--nav-title:hover
	background: #f0f0f0

.datepicker--nav-title.-disabled-
	background: none
	cursor: default

.datepicker--buttons
	display: -webkit-flex
	display: -ms-flexbox
	display: flex
	padding: 4px
	border-top: 1px solid #efefef

.datepicker--button
	display: -webkit-inline-flex
	display: -ms-inline-flexbox
	display: inline-flex
	flex: 1
	justify-content: center
	align-items: center
	height: 32px
	border-radius: 4px
	color: #4EB5E6
	cursor: pointer
	-webkit-flex: 1
	-ms-flex: 1
	-webkit-justify-content: center
	-ms-flex-pack: center
	-webkit-align-items: center
	-ms-flex-align: center

.datepicker--button:hover
	background: #f0f0f0
	color: #4a4a4a

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade-
	color: #dedede

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover
	color: #c5c5c5

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade-
	color: #dedede

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade-
	background: #a2ddf6
	color: white

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade-
	background: #8ad5f4

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade-
	background-color: rgba(92, 196, 239, 0.1)
	color: #cccccc

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade-
	background-color: rgba(92, 196, 239, 0.2)

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty
	border: none
	background: none

/* -------------------------------------------------
 Timepicker
------------------------------------------------- */
.datepicker--time
	position: relative
	display: -webkit-flex
	display: -ms-flexbox
	display: flex
	align-items: center
	padding: 4px
	border-top: 1px solid #efefef
	-webkit-align-items: center
	-ms-flex-align: center

.datepicker--time.-am-pm- .datepicker--time-sliders
	flex: 0 1 138px
	max-width: 138px
	-webkit-flex: 0 1 138px
	-ms-flex: 0 1 138px

.-only-timepicker- .datepicker--time
	border-top: none

.datepicker--time-sliders
	flex: 0 1 153px
	margin-right: 10px
	max-width: 153px
	-webkit-flex: 0 1 153px
	-ms-flex: 0 1 153px

.datepicker--time-label
	display: none
	font-size: 12px

.datepicker--time-current
	display: -webkit-flex
	display: -ms-flexbox
	display: flex
	flex: 1
	align-items: center
	margin: 0 0 0 10px
	text-align: center
	font-size: 14px
	-webkit-align-items: center
	-ms-flex-align: center
	-webkit-flex: 1
	-ms-flex: 1

.datepicker--time-current-colon
	margin: 0 2px 3px
	line-height: 1

.datepicker--time-current-hours, .datepicker--time-current-minutes
	position: relative
	z-index: 1
	font-size: 19px
	font-family: 'Century Gothic', CenturyGothic, AppleGothic, sans-serif
	line-height: 1

.datepicker--time-current-hours:after, .datepicker--time-current-minutes:after
	position: absolute
	top: -3px
	right: -2px
	bottom: -2px
	left: -2px
	z-index: -1
	border-radius: 4px
	background: #f0f0f0
	content: ''
	opacity: 0

.datepicker--time-current-hours.-focus-:after, .datepicker--time-current-minutes.-focus-:after
	opacity: 1

.datepicker--time-current-ampm
	align-self: flex-end
	margin-bottom: 1px
	margin-left: 6px
	color: #9c9c9c
	text-transform: uppercase
	font-size: 11px
	-webkit-align-self: flex-end
	-ms-flex-item-align: end

.datepicker--time-row
	display: -webkit-flex
	display: -ms-flexbox
	display: flex
	align-items: center
	height: 17px
	background: linear-gradient(to right, #dedede, #dedede) left 50% / 100% 1px no-repeat
	font-size: 11px
	-webkit-align-items: center
	-ms-flex-align: center

.datepicker--time-row:first-child
	margin-bottom: 4px

.datepicker--time-row input[type='range']
	flex: 1
	margin: 0
	padding: 0
	height: 100%
	background: none
	cursor: pointer
	-webkit-flex: 1
	-ms-flex: 1
	-webkit-appearance: none

.datepicker--time-row input[type='range']::-webkit-slider-thumb
	-webkit-appearance: none

.datepicker--time-row input[type='range']::-ms-tooltip
	display: none

.datepicker--time-row input[type='range']:hover::-webkit-slider-thumb
	border-color: #b8b8b8

.datepicker--time-row input[type='range']:hover::-moz-range-thumb
	border-color: #b8b8b8

.datepicker--time-row input[type='range']:hover::-ms-thumb
	border-color: #b8b8b8

.datepicker--time-row input[type='range']:focus
	outline: none

.datepicker--time-row input[type='range']:focus::-webkit-slider-thumb
	border-color: #5cc4ef
	background: #5cc4ef

.datepicker--time-row input[type='range']:focus::-moz-range-thumb
	border-color: #5cc4ef
	background: #5cc4ef

.datepicker--time-row input[type='range']:focus::-ms-thumb
	border-color: #5cc4ef
	background: #5cc4ef

.datepicker--time-row input[type='range']::-webkit-slider-thumb
	box-sizing: border-box
	width: 12px
	height: 12px
	border: 1px solid #dedede
	border-radius: 3px
	background: white
	cursor: pointer
	transition: background 0.2s

.datepicker--time-row input[type='range']::-moz-range-thumb
	box-sizing: border-box
	width: 12px
	height: 12px
	border: 1px solid #dedede
	border-radius: 3px
	background: white
	cursor: pointer
	transition: background 0.2s

.datepicker--time-row input[type='range']::-ms-thumb
	box-sizing: border-box
	width: 12px
	height: 12px
	border: 1px solid #dedede
	border-radius: 3px
	background: white
	cursor: pointer
	transition: background 0.2s

.datepicker--time-row input[type='range']::-webkit-slider-thumb
	margin-top: -6px

.datepicker--time-row input[type='range']::-webkit-slider-runnable-track
	height: 1px
	border: none
	background: transparent
	color: transparent
	cursor: pointer

.datepicker--time-row input[type='range']::-moz-range-track
	height: 1px
	border: none
	background: transparent
	color: transparent
	cursor: pointer

.datepicker--time-row input[type='range']::-ms-track
	height: 1px
	border: none
	background: transparent
	color: transparent
	cursor: pointer

.datepicker--time-row input[type='range']::-ms-fill-lower
	background: transparent

.datepicker--time-row input[type='range']::-ms-fill-upper
	background: transparent

.datepicker--time-row span
	padding: 0 12px

.datepicker--time-icon
	position: relative
	margin: 0 5px -1px 0
	width: 1em
	height: 1em
	border: 1px solid
	border-radius: 50%
	color: #9c9c9c
	font-size: 16px

.datepicker--time-icon:after, .datepicker--time-icon:before
	position: absolute
	background: currentColor
	content: ''

.datepicker--time-icon:after
	top: calc(50% + 1px)
	left: calc(50% - 1px)
	width: 1px
	height: 0.4em
	transform: translateY(-100%)
	-webkit-transform: translateY(-100%)

.datepicker--time-icon:before
	top: calc(50% + 1px)
	left: calc(50% - 1px)
	width: 0.4em
	height: 1px

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade-
	color: #dedede

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover
	color: #c5c5c5

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade-
	color: #dedede

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade-
	background: #a2ddf6
	color: white

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade-
	background: #8ad5f4

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade-
	background-color: rgba(92, 196, 239, 0.1)
	color: #cccccc

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade-
	background-color: rgba(92, 196, 239, 0.2)

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty
	border: none
	background: none
