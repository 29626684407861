// -----------------------------------------------
// Component - Slider
// -----------------------------------------------
.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal
	position unset
	margin-top 40px
	& .swiper-pagination-bullet
		background:var(--button-bg)
		border: 2px solid var(--element-border-color)
		box-shadow: var(--box-shadow-inset)
		border-radius: 12px
		opacity: 1
		height: 16px
		width: 16px
		transition all .4s
		&.swiper-pagination-bullet-active
			height: 16px
			width: 16px
			box-shadow: var(--box-shadow)
			background: linear-gradient(273.8deg, #D96331 15.88%, #E99018 98.99%)
			border: 2px solid var(--element-border-color)

